import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { filter, map, mergeMap, switchMap, tap, last } from 'rxjs/operators';
import { of, from, merge } from 'rxjs';
import { SearchMode } from 'src/search/models/search-mode.enum';
import { SearchField } from 'src/search/models/search-field';
import { SEARCH_FIELD_SPEC } from 'src/search/models/search-field-type-spec';
import { SearchEvent } from 'src/search/models/search-event';
import { LocaleService } from './locale.service';

declare let gtag: Function;

export enum ScrollType {
  top = 1,
  bottom = 2,
  middle = 3
}

export function parseScrollEvent(event: WheelEvent, target: HTMLElement) {
  const atBottom = target.scrollHeight - Math.ceil(target.scrollTop) === target.clientHeight;
  const atTop = Math.ceil(target.scrollTop) === 0;

  if (event.deltaY > 0 && atBottom)
    return ScrollType.bottom;
  else if (event.deltaY < 0 && atTop)
    return ScrollType.top;
  else
    return ScrollType.middle;
}

export function clone<T>(obj: T): T {
  if (!obj)
    return obj;
  return <T>JSON.parse(JSON.stringify(obj));
}

export function initLocalization(
  activatedRoute: ActivatedRoute,
  translate: TranslateService,
  localeService: LocaleService
) {
  translate.setDefaultLang('bg');

  translate.onLangChange
    .subscribe((event: LangChangeEvent) => {
      localeService.changeCulture(event.lang);
    });

  activatedRoute.queryParamMap.subscribe(params => {
    if (params.has('lang')
      && (params.get('lang') === 'bg' || params.get('lang') === 'en')) {
      translate.use(params.get('lang'));
    }
  });

  setTimeout(() => {
    if (!translate.currentLang)
      translate.use(localeService.culture);
  }, 0);
}

export function getPageTitle(
  router: Router,
  activatedRoute: ActivatedRoute,
  translateService: TranslateService
) {
  return router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
      switchMap(data => {
        if (data.data && data.data.name)
          return of(data.data.name);
        else if (data.publication && data.publication.titles && data.publication.titles[0] && typeof data.publication.titles[0] === 'string')
          return of(data.publication.titles[0]);
        else
          return translateService.stream(data['title'] || 'pages.default');
      })
    );
}

export function getPageLanguage(
  router: Router
) {
  return router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(_ => {
        let url = window.location.href;

        if (url.indexOf('/en/') !== -1)
          return 'en';
        else if (url.indexOf('/bg/') !== -1)
          return 'bg';
        else return null;
      }),
      filter(e => e != null)
    );
}

export function getNavigationForGtag(router: Router) {
  return router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map((e: NavigationEnd) => e.urlAfterRedirects)
    );
}

export function gtagConfigEvent(url: string, trackingId: string) {
  gtag('config', trackingId, {
    'page_path': url
  });
}

export function scrollToTop(behavior: 'auto' | 'smooth' = 'smooth') {
  document.body.scrollTo({
    top: 0,
    left: 0,
    behavior
  });
}

export const urlRegex = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/);

export const copyText = (text: string) => {
  if (!navigator.clipboard) {
    // FALLBACK FOR OLDER BROWSERS

    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let successful = false;

    try {
      successful = document.execCommand('copy');
    } catch (err) {
      successful = false;
    }

    document.body.removeChild(textArea);
    return of(successful)
  }
  else {
    return from(navigator.clipboard.writeText(text).then(_ => true, _ => false));
  }
};

export const bindSearchParams = (self: any) => (model: SearchEvent) => {
  self.searchFields = (model.q || [])
    .filter((field: SearchField) => field.searchTerm !== SEARCH_FIELD_SPEC[field.type].defaultValue);
  self.searchMode = model.mode || SearchMode.simple;
  self.page = model.page || 0;
}