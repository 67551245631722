import { LogicalOperator } from '../models/logical-operator.enum';
import { SearchField } from '../models/search-field';
import { SearchFieldMode } from '../models/search-field-mode.enum';
import { SearchFieldControlType, SEARCH_FIELD_SPEC } from '../models/search-field-type-spec';
import { SearchFieldCategories, SearchFieldType, SearchFieldTypeValuesArray } from '../models/search-field-type.enum';

const buildFieldQuery = (field: SearchField) => {
  if (field.mode === SearchFieldMode.exact) {
    return {
      term: {
        [SEARCH_FIELD_SPEC[field.type].name]: field.searchTerm
      }
    };
  } else if (field.mode === SearchFieldMode.matchPhrase) {
    return {
      match_phrase: {
        [SEARCH_FIELD_SPEC[field.type].name]: field.searchTerm
      }
    };
  } else if (field.mode === SearchFieldMode.fts) {
    return {
      match: {
        [SEARCH_FIELD_SPEC[field.type].name]: field.searchTerm
      }
    };
  } else if (field.mode === SearchFieldMode.allFts) {
    const ftsFields = SearchFieldTypeValuesArray
      .filter(field => SEARCH_FIELD_SPEC[SearchFieldType[field]].defaultMode === SearchFieldMode.fts);
    return {
      multi_match: {
        fields: ftsFields.map(field => SEARCH_FIELD_SPEC[SearchFieldType[field]].name),
        query: field.searchTerm,
        boost: 1 / ftsFields.length
      }
    };
  } else {
    return null;
  }
};

export const buildAggregations = (fields: SearchField[]): object => {
  let result = {
    aggs: {},
    size: 0
  };

  SearchFieldCategories.forEach(category => {
    let AND_FILTERS = {
      bool: {
        must: fields
          .filter(field => field.type != category
            && field.logicalOperator == LogicalOperator.AND)
          .map(buildFieldQuery)
          .filter(e => e)
      }
    };

    let OR_FILTERS = fields
      .filter(field => field.type
        && field.logicalOperator == LogicalOperator.OR)
      .map(buildFieldQuery)
      .filter(e => e);

    let spec = SEARCH_FIELD_SPEC[category];

    result.aggs[spec.name] = {
      filter: {
        bool: {
          should: (<any>OR_FILTERS).concat([AND_FILTERS])
        }
      },
      aggs: {
        filteredAggregation: {
          terms: {
            field: spec.name,
            size: 1000000
          }
        }
      }
    }
  });

  return result;
}

const _buildCategoryFilters = (fields: SearchField[]) => {
  return SearchFieldTypeValuesArray.map(category => {
    return {
      bool: {
        should: fields
          .filter(field => field.type === SearchFieldType[category])
          .map(buildFieldQuery)
          .filter(e => e)
      }
    };
  }).filter(e => e.bool.should.length > 0);
};

export const buildCategoryFilters = (fields: SearchField[]) => {
  return {
    query: {
      bool: {
        must: _buildCategoryFilters(fields)
      }
    }
  };
};

export const buildCategoryAggregations = (fields: SearchField[]) => {
  let result = {
    aggs: {},
    size: 0
  };

  SearchFieldCategories.forEach(category => {
    let fieldsInOtherCategories = fields.filter(e => e.type !== category);
    let spec = SEARCH_FIELD_SPEC[category];

    result.aggs[spec.name] = {
      filter: {
        bool: {
          must: _buildCategoryFilters(fieldsInOtherCategories)
        }
      },
      aggs: {
        filteredAggregation: {
          terms: {
            field: spec.name,
            size: 1000000
          }
        }
      }
    }
  });

  return result;
};

export const buildFilters = (fields: SearchField[]): object => {
  const AND_FILTERS = {
    bool: {
      must: fields
        .filter(field => field.logicalOperator === LogicalOperator.AND)
        .map(buildFieldQuery)
        .filter(e => e)
    }
  };
  const OR_FILTERS = fields
    .filter(field => field.logicalOperator === LogicalOperator.OR)
    .map(buildFieldQuery)
    .filter(e => e);

  return {
    query: {
      bool: {
        should: (<any>OR_FILTERS).concat([AND_FILTERS])
      }
    }
  };
};


export const parseSearchField = (field: SearchField): SearchField[] => {
  if (SEARCH_FIELD_SPEC[field.type].controlType !== SearchFieldControlType.text) {
    return [field];
  }

  const TokenType = {
    FREETEXT: 1,
    AND: 2,
    NOT: 3
  };

  const tokens = (field.searchTerm
    .match(/(\+\W?[а-яА-Яa-zA-Z0-9]+)|(\-\W?[а-яА-Яa-zA-Z0-9]+)|([а-яА-Яa-zA-Z0-9]+)/g) || [])
    .map(e => {
      let tokenType = TokenType.FREETEXT;
      if (e.match(/^\+/)) {
        tokenType = TokenType.AND;
      }

      if (e.match(/^\-/)) {
        tokenType = TokenType.NOT;
      }

      return {
        type: tokenType,
        value: e.replace("+", "").replace("-", "").trim()
      };
    });

  const metadata = {
    FREE_TEXT_TOKENS: [tokens.filter(e => e.type === TokenType.FREETEXT).map(e => e.value).join(" ")],
    AND_TOKENS: tokens.filter(e => e.type === TokenType.AND).map(e => e.value),
    NOT_TOKENS: tokens.filter(e => e.type === TokenType.NOT).map(e => e.value)
  };

  // Ignoring `NOT` tokens for now
  const result = metadata.FREE_TEXT_TOKENS
    .map(token => new SearchField(field.type, token, null, LogicalOperator.AND))
    .concat(metadata.AND_TOKENS.map(token => new SearchField(field.type, token, null, LogicalOperator.AND)));

  return result;
};

export class LogicalOperatorQueryBuilder {
  buildAggregations = buildAggregations;
  buildFilters = buildFilters;
  buildCategoryAggregations = buildCategoryAggregations;
  buildCategoryFilters = buildCategoryFilters;
  parseSearchField = parseSearchField;
}
