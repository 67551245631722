import { Component, OnDestroy, OnInit } from '@angular/core';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginatorChangeEvent } from 'src/components/paginator/paginator.component';
import { LoadingIndicatorService } from 'src/infrastructure/loading-indicator.service';
import { bindSearchParams, scrollToTop } from 'src/infrastructure/utils';
import { SearchField } from 'src/search/models/search-field';
import { SearchFieldType } from 'src/search/models/search-field-type.enum';
import { SearchMode } from 'src/search/models/search-mode.enum';
import { SearchResultItem } from 'src/search/models/search-result-item';
import * as QueryBuilder from 'src/search/services/logical-operator-query-builder.service';
import { SearchResource } from 'src/search/services/search.resource';
import { SearchState } from 'src/search/services/search.state';

@Component({
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  constructor(
    private resource: SearchResource,
    private loadingIndicator: LoadingIndicatorService,
    private state: SearchState,
  ) {
    this.loading = loadingIndicator.change$;
  }

  searchIcon = faSearch;
  faTimesCircle = faTimesCircle;
  searchMode: SearchMode = SearchMode.simple;
  searchFields: SearchField[];
  items: SearchResultItem[];
  categories: any = null;
  totalCount: number = -1;
  page: number = 0;
  pageSizes = [10, 25, 50, 100];
  pageSize = this.pageSizes[0];
  SearchMode = SearchMode;
  loading: any;

  destroyed$: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.state.change$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(queryParams => {
        bindSearchParams(this)(queryParams);
        this.search();
      });
    scrollToTop();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  search(): void {
    let aggregationsQuery, searchQuery;
    if (this.searchMode === SearchMode.simple) {
      aggregationsQuery = QueryBuilder.buildCategoryAggregations(this.searchFields);
      searchQuery = QueryBuilder.buildCategoryFilters(this.searchFields);
    } else {
      aggregationsQuery = QueryBuilder.buildAggregations(this.searchFields);
      searchQuery = QueryBuilder.buildFilters(this.searchFields);
    }

    searchQuery['from'] = this.pageSize * this.page;
    searchQuery['size'] = this.pageSize;

    this.loadingIndicator.start();
    this.resource.search(searchQuery, aggregationsQuery)
      .subscribe(res => {
        this.totalCount = res.totalCount;
        this.items = res.items;
        this.categories = this.formatCategories(res.categories);
        this.loadingIndicator.stop();
      }, _ => this.loadingIndicator.stop());
  }

  handlePageEvent(event: PaginatorChangeEvent): void {
    this.state.change$.next({
      q: this.searchFields,
      page: event.page,
      mode: this.searchMode
    });

    scrollToTop();
  }

  formatCategories(categories: any): void {
    if (!categories) {
      return null;
    }

    const fields = this.searchFields;
    Object.keys(categories).forEach((category: string) => {
      categories[category] = categories[category].filter(item => item.count > 0);

      categories[category]
        .forEach(item => {
          item.selected = fields.some((e: SearchField) => e.type === SearchFieldType[category]
            && e.searchTerm === item.key);
        });
    });
    return categories;
  }

  clearFilter() {
    this.state.change$.next({});
  }
}
