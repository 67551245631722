export enum LogicalOperator {
  AND = 1,
  OR = 2
}

export const LogicalOperatorsToNumberArray: LogicalOperator[] = Object.keys(LogicalOperator)
  .map(key => parseInt(key, 10))
  .filter(key => key >= 0);

export const LogicalOperatorsToValuesArray: string[] = LogicalOperatorsToNumberArray
  .map(key => LogicalOperator[key]);