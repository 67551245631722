import { Component } from "@angular/core";

@Component({
  selector: 'globe-icon',
  template: `
  <svg width="20px" height="20px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Outline" transform="translate(-50.000000, -50.000000)" stroke="currentColor">
            <g id="globe" transform="translate(50.000000, 50.000000)">
                <path d="M24.5,12.5 C24.5,15.58 23.35,18.38 21.45,20.5 C19.25,22.96 16.06,24.5 12.5,24.5 C8.94,24.5 5.75,22.96 3.55,20.5 C1.65,18.38 0.5,15.58 0.5,12.5 C0.5,9.42 1.65,6.62 3.55,4.5 C5.75,2.04 8.94,0.5 12.5,0.5 C16.06,0.5 19.25,2.04 21.45,4.5 C23.35,6.62 24.5,9.42 24.5,12.5 Z" id="Stroke-1"></path>
                <path d="M18.5,12.5 C18.5,19.127 15.813,24.5 12.5,24.5 C9.186,24.5 6.5,19.127 6.5,12.5 C6.5,5.873 9.186,0.5 12.5,0.5 C15.813,0.5 18.5,5.873 18.5,12.5 Z" id="Stroke-3"></path>
                <path d="M3.5498,20.5 C5.7498,19.27 8.9398,18.5 12.4998,18.5 C16.0598,18.5 19.2498,19.27 21.4498,20.5" id="Stroke-5"></path>
                <path d="M21.4502,4.5 C19.2502,5.73 16.0602,6.5 12.5002,6.5 C8.9402,6.5 5.7502,5.73 3.5502,4.5" id="Stroke-7"></path>
                <path d="M12.5,0.5 L12.5,24.5" id="Stroke-9"></path>
                <path d="M24.5,12.5 L0.5,12.5" id="Stroke-11"></path>
            </g>
        </g>
    </g>
</svg>`
})
export class GlobeIcon {

}