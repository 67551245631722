import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import * as KUTE from 'kute.js/kute.js';
import 'kute.js/kute-svg.js';

@Component({
  selector: 'lightbulb-icon',
  template: `
  <svg id="lightbulb"
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 244.26 258.45" [attr.width]="width" [attr.height]="height" style="transform: rotateZ(-15deg);">
  <path id="body"
        class="cls-1"
        d="M128.62,69.59c-17.5-1.39-30.43,5.52-35.34,8.22A64.89,64.89,0,0,0,75.12,92.65C71.46,97,65.69,104,63.32,114.81a44.24,44.24,0,0,0-1,10.17c.21,12.13,5.59,21.13,10.33,28.83,1,1.68,7.68,12.49,15.5,20.51a55.25,55.25,0,0,1,7.92,10,62.21,62.21,0,0,1,3.25,6.12c4.09,9.06,4.68,21.73,5.17,29,1.26,18.72,2,28.15,6.67,31.5,3.68,2.64,8.33,2.56,17,2.41,8.33-.14,12.5-.21,15.32-2.58,5.52-4.62,2.38-12.9,3.19-33.68.4-10.28.6-15.42,2.25-22.13,3.53-14.35,11-26.13,16.07-34.19,4.27-6.76,5.4-7.47,8.42-13.16s7.31-13.79,8.36-22.4c2.33-19.09-12.24-34.76-14.89-37.6C152.67,72.4,134.52,70.06,128.62,69.59Z" />
  <path id="bottom"
        class="cls-2"
        d="M113.31,254.42,148,236.1c4.19-2.21,1.92-8.13-2.27-8.39l-38.08-2.35,2.27,8.39,37-17.83c4.09-2,2-8.48-2.27-8.39l-39.72.9c-3.16.07-4.81,2.8-4.34,5.7l6.12,37.62c.93,5.7,9.6,3.28,8.68-2.39l-6.12-37.62-4.34,5.7,39.72-.9-2.27-8.39-37,17.83c-4.31,2.08-1.82,8.13,2.27,8.39l38.08,2.35-2.27-8.39-34.71,18.33C103.65,249.36,108.19,257.13,113.31,254.42Z" />
  <path id="reflection"
        class="cls-2"
        d="M99.38,89.25c-11.06-.76-25.17,20.47-24.6,35.86.15,4.12,1.37,8.17,2.77,12.82,1.77,5.88,2.89,7.19,4.16,7.45,1.67.34,3.68-1.14,4.68-2.77,2.84-4.63-3.38-9-3.81-17-.64-11.87,11.87-26.21,19.4-30.49.84-.48,3.49-1.89,3.29-3.29C105.05,90.25,101.23,89.37,99.38,89.25Z" />

  <!-- Light rays -->
  <path id="ray-1"
        class="cls-3"
        d="M27.58,82c12.48,6,28.38,11.68,26.6,15.42S38.47,92.79,25.44,86.48C13,80.45-1.3,74.52.09,71.37S15.74,76.3,27.58,82Z" />
  <path id="ray-2"
        class="cls-3"
        d="M53.25,47.16c10.43,9.08,24.23,18.84,21.52,22S60.85,60.5,50,50.94C39.6,41.81,27.41,32.28,29.59,29.61S43.35,38.54,53.25,47.16Z" />
  <path id="ray-3"
        class="cls-3"
        d="M106.55,28c3.27,13.44,8.89,29.37,4.87,30.36S105,43.31,101.69,29.22C98.49,15.78,94.05,1,97.38,0S103.45,15.28,106.55,28Z" />
  <path id="ray-4"
        class="cls-3"
        d="M162.37,33.55c-2.78,13.55-4.5,30.36-8.56,29.53s.67-16.36,3.66-30.53C160.32,19,162.63,3.72,166,4.32S165,20.69,162.37,33.55Z" />
  <path id="ray-5"
        class="cls-3"
        d="M203.37,63.6c-9.54,10-19.92,23.34-22.92,20.5s9.25-13.51,19.3-23.94c9.59-10,19.67-21.7,22.23-19.4S212.43,54.1,203.37,63.6Z" />
  <path id="ray-6"
        class="cls-3"
        d="M216.22,93.74c12-6.84,25.8-16.62,27.85-13S231.32,91,218.7,98.08c-12.05,6.77-25.08,15.12-26.88,12.17S204.81,100.23,216.22,93.74Z" />
</svg>`,
  styles: [`
  .cls-1 {
    fill: none;
    stroke: currentColor;
    stroke-miterlimit: 10;
    stroke-width: 10px;
  }
  
  .cls-2 {
    fill: currentColor;
  }
  
  .cls-3 {
    fill: currentColor;
  }
  `]
})
export class LightbulbIcon implements OnInit, OnDestroy {
  @Input()
  animate: boolean = undefined;

  @Input()
  width: string = "50px";

  @Input()
  height: string = "50px";

  animationOptions = {
    offset: 100,
    duration: 6 * 100 * 2,
    repeat: 1000,
    easing: 'linear'
  };

  animation: any;

  ngOnInit() {
    if (this.animate !== undefined) {
      this.animation = KUTE.allFromTo('.cls-3', { color: '#ff931e' }, { color: '#3a3632' }, this.animationOptions);
      this.animation.start();
    }

  }

  ngOnDestroy(): void {
    if (this.animation)
      this.animation.stop();
  }
}