import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/infrastructure/configuration/configuration';
import { InformationItem } from './information-type';

@Injectable({
  providedIn: 'root'
})
export class InformationResource {

  constructor(
    private config: Configuration,
    private http: HttpClient
  ) { }

  getNews(page, pageSize) {
    let url = `${this.config.restUrl}/information/news?limit=${pageSize}&offset=${page * pageSize}`;
    return this.http.get<{ items: InformationItem[], totalCount: number }>(url);
  }

  getEvents(page, pageSize) {
    let url = `${this.config.restUrl}/information/events?limit=${pageSize}&offset=${page * pageSize}`;
    return this.http.get<{ items: InformationItem[], totalCount: number }>(url);
  }
}