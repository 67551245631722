import { Component, OnInit } from '@angular/core';
import { InformationItem } from '../information/information-type';
import { getPaginatedNews } from '../information/information-data-mock';
import { faPaperclip, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { scrollToTop } from 'src/infrastructure/utils';
import { PaginatorChangeEvent } from 'src/components/paginator/paginator.component';
import { LocaleService } from 'src/infrastructure/locale.service';
import { InformationResource } from '../information/information.resource';
import { faFile } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['../news-common-styles.scss']
})
export class NewsComponent implements OnInit {
  constructor(
    public locale: LocaleService,
    private resource: InformationResource
  ) { }

  ngOnInit() {
    this.getData();
  }

  page = 0;
  pageSize = 6;
  totalCount = 0;
  items: InformationItem[] = [];

  linkIcon = faCaretRight;
  fileIcon = faFile;

  getData() {
    this.resource.getNews(this.page, this.pageSize)
      .subscribe(({ totalCount, items }) => {
        this.items = items;
        this.totalCount = totalCount;
        scrollToTop();
      });
  }

  back() {
    window.history.back();
  }

  handlePageEvent(event: PaginatorChangeEvent) {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.getData();
  }
}
