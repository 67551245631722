import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./components/page-not-found.component";
import { RedirectRewardComponent } from "./components/redirect-reward.component";
import { EAuthResponseComponent } from "./e-auth/components/e-auth-response.component";

const routes: Routes = [
  {
    path: "reward",
    component: RedirectRewardComponent
  },
  {
    path: "eAuthResponse",
    component: EAuthResponseComponent
  },
  {
    path: "**",
    component: PageNotFoundComponent,
    data: {
      title: "pages.notFound"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
