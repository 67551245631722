import { Injectable, EventEmitter } from "@angular/core";
import { ReplaySubject } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  subject: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  change$ = this.subject.pipe(debounceTime(300), distinctUntilChanged());

  start() {
    this.subject.next(true);
  }

  stop() {
    this.subject.next(false)
  }
}