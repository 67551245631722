import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationComponent } from './components/organization/organization.component';
import { OrganizationsDataResolverService } from './services/organization-data-resolver.service';

const routes: Routes = [
  {
    path: 'organizations',
    children: [
      {
        path: '',
        component: OrganizationComponent,
        data: {
          title: 'pages.organizations'
        },
        resolve: {
          data: OrganizationsDataResolverService
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ClassificationsRouting { }