import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchFieldType } from 'src/search/models/search-field-type.enum';
import { SearchField } from 'src/search/models/search-field';
import { Classification } from 'src/classifications/models/classification';
import { SearchMode } from 'src/search/models/search-mode.enum';

@Component({
  templateUrl: './scientific-area.component.html',
  styleUrls: [
    '../../styles/common.scss',
    './scientific-area.component.scss'
  ]
})
export class ScientificAreaComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.data.subscribe(({ data }) => {
      this.data = data;
    });
  }

  data: Classification[] = [];

  // area: Classification
  // [new SearchField(SearchFieldType.classifications, area.id, area)]
}
