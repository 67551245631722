import { Component, OnInit } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { faGlobe, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { LoadingIndicatorService } from 'src/infrastructure/loading-indicator.service';

@Component({
  selector: 'ab-language-selector',
  templateUrl: 'language-selector.component.html',
  styleUrls: ['language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private loadingIndicator: LoadingIndicatorService
  ) { }

  languages = [
    { name: 'Български', language: 'en' },
    { name: 'English', language: 'bg' }
  ];

  loadingIcon = faSpinner;
  selected = this.languages.find(language => language.language == this.translateService.currentLang);
  loading = false;

  ngOnInit() {
    this.loadingIndicator.change$.subscribe(next => this.loading = next);
    this.translateService.onLangChange.subscribe(lang => this.selected = this.languages.find(language => language.language == lang.lang))
  }

  selectLanguage() {
    if (this.selected.language === 'en')
      this.selected = this.languages[1];
    else
      this.selected = this.languages[0];

    this.loadingIndicator.start();
    this.translateService.use(this.selected.language)
      .subscribe(_ => this.loadingIndicator.stop(), _ => this.loadingIndicator.stop());
  }
}