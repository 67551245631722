import { Component, OnInit } from "@angular/core";
import { EAuthResponseStatus } from "../enums/e-auth-response-status.enum";
import { ActivatedRoute, Router } from "@angular/router";
import { EAuthUserService } from "../services/e-auth-user.service";

@Component({
    selector: 'e-auth-response',
    templateUrl: './e-auth-response.component.html'
})
export class EAuthResponseComponent implements OnInit {

    responseStatus: any;
    errorMessage: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private eAuthUserService: EAuthUserService
    ) {
    }

    ngOnInit() {
        this.responseStatus = EAuthResponseStatus[this.route.snapshot.queryParams.responseStatus];

        if (this.responseStatus === EAuthResponseStatus.Success) {
            this.eAuthUserService.isLogged = true;
            this.eAuthUserService.userName = this.route.snapshot.queryParams.name;
            this.router.navigate(['/']);
        } else {
            this.setErrorMessage();
        }
    }

    setErrorMessage() {
        if (this.responseStatus === EAuthResponseStatus.AuthenticationFailed) {
            this.errorMessage = 'Проблем при автентикацията';
        }
        if (this.responseStatus === EAuthResponseStatus.CanceledByUser) {
            this.errorMessage = 'Процеса е прекъснат от потребител';
        }
        if (this.responseStatus === EAuthResponseStatus.InvalidResponseXML) {
            this.errorMessage = 'Грешка в генерирания XML';
        }
        if (this.responseStatus === EAuthResponseStatus.InvalidSignature) {
            this.errorMessage = 'Невалиден подпис';
        }
        if (this.responseStatus === EAuthResponseStatus.MissingEGN) {
            this.errorMessage = 'Липсва ЕГН';
        }
        if (this.responseStatus === EAuthResponseStatus.NotDetectedQES) {
            this.errorMessage = 'Подписът не е КЕП';
        }
    }
}