import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgBootstrapModule } from 'src/infrastructure/ng-bootstrap.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentsModule } from 'src/components/components.module';

import { SearchComponent } from './components/search/search.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { SearchRoutingModule } from './search-routing.module';
import { SearchResource } from './services/search.resource';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { SearchCategoriesComponent } from './components/search-categories/search-categories.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { SearchSidebarComponent } from './components/search-sidebar/search-sidebar.component';
import { InformationComponent } from './components/information/information.component';
import { SharedModule } from 'src/shared/shared.module';
import { NewsComponent } from './components/news/news.component';
import { EventsComponent } from './components/events/events.component';
import { InformationItemComponent } from './components/information/information-item/information-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SearchRoutingModule,
    TranslateModule,
    NgBootstrapModule,
    ComponentsModule,
    SharedModule,
    FontAwesomeModule
  ],
  declarations: [
    SearchComponent,
    SearchResultComponent,
    SearchFilterComponent,
    LandingPageComponent,
    SearchCategoriesComponent,
    SearchSidebarComponent,
    InformationComponent,
    NewsComponent,
    EventsComponent,
    InformationItemComponent
  ],
  providers: [
    SearchResource
  ]
})
export class SearchModule { }
