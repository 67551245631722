import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EAuthUserService } from "../services/e-auth-user.service";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'e-auth-profile-modal',
    templateUrl: './e-auth-profile-modal.component.html'
})
export class EAuthProfileModalComponent {

    faTimes = faTimes;

    constructor(
        private activeModal: NgbActiveModal,
        public eAuthUserService: EAuthUserService
    ) {
    }

    close() {
        this.activeModal.close(null);
    }

    exit() {
        this.eAuthUserService.isLogged = false;
        this.eAuthUserService.userName = null;
        this.close();
    }
}