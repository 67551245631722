import { Component, OnInit, Input } from '@angular/core';
import { Publication } from 'src/publication/models/publication';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'publication-details',
  templateUrl: './publication-details.component.html',
  styleUrls: ['./publication-details.component.scss']
})
export class PublicationDetailsComponent implements OnInit {
  @Input()
  publication: Publication;
  detailsIcon = faInfoCircle;
  showDetails = false;

  contributors: string = null;
  fundingReference: string = null;
  relatedIdentifiers: string = null;
  alternativeIdentifiers: string = null;
  sources: string = null;
  coverages: string = null;

  constructor() { }

  ngOnInit() {
    // This is needed because text-align: justify breaks my soul
    this.contributors = this.publication.contributors.map(e => e.name.trim()).join("; ");
    this.fundingReference = this.publication.fundingReference.map(e => `${e.funderName.trim()}: ${e.awardTitle.trim()} (${e.awardNumber.trim()})`).join("; ");
    this.relatedIdentifiers = this.publication.relatedIdentifiers.map(e => e.trim()).join("; ");
    this.alternativeIdentifiers = this.publication.alternativeIdentifiers.map(e => e.trim()).join("; ");
    this.sources = this.publication.sources.map(e => e.trim()).join("; ");
    this.coverages = this.publication.coverages.map(e => e.trim()).join("; ");
  }
}
