import { Injectable } from "@angular/core";
import { Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/infrastructure/configuration/configuration';
import { take, mergeMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { LoadingIndicatorService } from 'src/infrastructure/loading-indicator.service';
import { Classification } from '../models/classification';
import { SEARCH_FIELD_SPEC } from 'src/search/models/search-field-type-spec';
import { SearchFieldType } from 'src/search/models/search-field-type.enum';
import { LocaleService } from 'src/infrastructure/locale.service';



@Injectable()
export class OrganizationsDataResolverService implements Resolve<Classification[]> {

  constructor(
    private http: HttpClient,
    private config: Configuration,
    private router: Router,
    private loadingIndicator: LoadingIndicatorService,
    private localService: LocaleService
  ) { }

  resolve() {
    this.loadingIndicator.start();

    let classificationsSpec = SEARCH_FIELD_SPEC[SearchFieldType.classifications];

    let query = {
      aggregations: {
        [classificationsSpec.name]: {
          terms: {
            field: classificationsSpec.name,
            size: 100000
          }
        }
      },
      size: 0
    };

    return this.http
      .post<Classification[]>(`${this.config.restUrl}/institution/hierarchy?lang=${this.localService.culture}`, query)
      .pipe(
        mergeMap(hierarchy => {
          this.loadingIndicator.stop();
          if (hierarchy && hierarchy.length) {
            return of(hierarchy);
          }
          else {
            this.router.navigate(['/notFound']);
            return EMPTY;
          }
        })
      );
  }
}