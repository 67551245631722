import { ControlMetadata } from './control-metadata.model';
import { SearchFieldMode } from './search-field-mode.enum';
import { SearchFieldType } from './search-field-type.enum';
import { SEARCH_FIELD_SPEC, SearchFieldControlType } from './search-field-type-spec';
import { clone } from '../../infrastructure/utils';
import { LogicalOperator } from './logical-operator.enum';

export class SearchField {
  searchTerm: string;
  name: string;
  mode: SearchFieldMode;
  type: SearchFieldType;
  logicalOperator: LogicalOperator;
  controlType: SearchFieldControlType;
  controlMetadata: ControlMetadata;

  constructor(type?: SearchFieldType, value?: any, model?: any, logicalOperator?: LogicalOperator) {
    this.type = (type || SearchFieldType.all);
    this.name = SEARCH_FIELD_SPEC[this.type].name;
    this.mode = SEARCH_FIELD_SPEC[this.type].defaultMode;
    this.searchTerm = SEARCH_FIELD_SPEC[this.type].defaultValue;
    this.logicalOperator = LogicalOperator.AND;
    this.controlType = SEARCH_FIELD_SPEC[this.type].controlType;
    this.controlMetadata = clone(SEARCH_FIELD_SPEC[this.type].controlMetadata);

    if (value != undefined)
      this.searchTerm = value;

    if (model !== undefined)
      this.controlMetadata.model = model;
  }
}

export function changeType(field: SearchField, type: SearchFieldType) {
  if (type) {
    let spec = SEARCH_FIELD_SPEC[type];
    field.type = type;
    field.name = spec.name;
    field.mode = spec.defaultMode;
    field.controlType = spec.controlType;
    field.controlMetadata = clone(spec.controlMetadata);
    field.searchTerm = spec.defaultValue;
  }
}