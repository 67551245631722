import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { bindSearchParams, clone } from 'src/infrastructure/utils';
import { LogicalOperator, LogicalOperatorsToNumberArray } from 'src/search/models/logical-operator.enum';
import { changeType, SearchField } from 'src/search/models/search-field';
import { SearchFieldMode } from 'src/search/models/search-field-mode.enum';
import { SearchFieldControlType, SEARCH_FIELD_SPEC } from 'src/search/models/search-field-type-spec';
import { SearchFieldNumberValues, SearchFieldType } from 'src/search/models/search-field-type.enum';
import { SearchMode } from 'src/search/models/search-mode.enum';
import { SearchState } from 'src/search/services/search.state';

@Component({
  selector: 'search-filter-2',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnDestroy {

  constructor(
    private router: Router,
    private state: SearchState,
    private translate: TranslateService
  ) {
    this.currentLang = translate.currentLang;

    this.translate.onLangChange
      .pipe(takeUntil(this.destroyed$))
      .subscribe(lang => this.currentLang = lang.lang);

    this.state.change$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(queryParams => this.fillFilterModels(queryParams));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  @Input('clearFilter')
  set clearFilter(value: any) {
    this.state.change$.next({});
  }

  destroyed$: Subject<boolean> = new Subject<boolean>();
  searchFields: SearchField[];
  searchMode: SearchMode = SearchMode.simple;

  filter = {
    quickSearch: new SearchField(),
    fields: [new SearchField()]
  };
  SearchFieldTypeValues = SearchFieldNumberValues;
  SearchFieldType = SearchFieldType;
  SearchFieldControlType = SearchFieldControlType;
  SearchFieldMode = SearchFieldMode;
  LogicalOperator = LogicalOperator;
  LogicalOperatorValues = LogicalOperatorsToNumberArray;
  SEARCH_FIELD_SPEC = SEARCH_FIELD_SPEC;
  SearchMode = SearchMode;
  faMinus = faMinus;
  faPlus = faPlus;
  faSearch = faSearch;
  currentLang: string = null;

  fillFilterModels(queryParams: any): void {
    bindSearchParams(this)(queryParams);
    if (this.searchMode === SearchMode.simple)
      this.filter = {
        quickSearch: this.searchFields.find(e => e.mode == SearchFieldMode.allFts) || new SearchField(),
        fields: [new SearchField()]
      };
    else
      this.filter = {
        quickSearch: new SearchField(),
        fields: this.searchFields.length ? this.searchFields : [new SearchField()]
      };
  }

  search(): void {
    if (this.searchMode === SearchMode.simple) {
      this.state.change$.next({
        q: [this.filter.quickSearch],
        mode: SearchMode.simple
      });
    } else {
      this.state.change$.next({
        q: this.filter.fields,
        mode: SearchMode.advanced
      });
    }

    this.router.navigate(['/search']);
  }

  changeFieldType(field: SearchField, type: SearchFieldType) {
    changeType(field, type);
  }

  removeField(index: number) {
    this.filter.fields.splice(index, 1);
  }

  addField() {
    this.filter.fields.push(new SearchField());
  }

  handleFieldModelChange(field: SearchField, model: any) {
    field.controlMetadata.model = clone(model);
  }

  handleEnter(event: KeyboardEvent, searchMode: SearchMode) {
    this.search();
    return false;
  }

  handleFormSubmit(event: any) {
    return false;
  }
}
