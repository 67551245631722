import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ClassificationsModule } from "src/classifications/classifications.module";
import { Configuration, configurationFactory } from "src/infrastructure/configuration/configuration";
import { LocaleService } from "src/infrastructure/locale.service";
import { PagesModule } from "src/pages/pages.module";
import { PublicationModule } from "src/publication/publication.module";
import { ComponentsModule } from "../components/components.module";
import { NgBootstrapModule } from "../infrastructure/ng-bootstrap.module";
import { SearchModule } from "../search/search.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/footer.component";
import { MenuComponent } from "./components/menu.component";
import { PageNotFoundComponent } from "./components/page-not-found.component";
import { RedirectRewardComponent } from "./components/redirect-reward.component";
import { EAuthResource } from "./e-auth/e-auth.resource";
import { EAuthSendRequestModalComponent } from "./e-auth/components/e-auth-send-request-modal.component";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EAuthUserService } from "./e-auth/services/e-auth-user.service";
import { EAuthProfileModalComponent } from "./e-auth/components/e-auth-profile-modal.component";
import { EAuthResponseComponent } from "./e-auth/components/e-auth-response.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    PageNotFoundComponent,
    RedirectRewardComponent,
    EAuthSendRequestModalComponent,
    EAuthProfileModalComponent,
    EAuthResponseComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FontAwesomeModule,
    NgbModule,
    NgBootstrapModule,
    ComponentsModule,
    SearchModule,
    PublicationModule,
    ClassificationsModule,
    PagesModule,
    AppRoutingModule
  ],
  entryComponents: [
    EAuthSendRequestModalComponent,
    EAuthProfileModalComponent
  ],
  providers: [
    Configuration,
    EAuthResource,
    EAuthUserService,
    NgbActiveModal,
    {
      provide: APP_INITIALIZER,
      useFactory: configurationFactory,
      deps: [Configuration],
      multi: true
    },
    LocaleService,
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (LocaleService: { locale: string }) => LocaleService.locale
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
