import { NgModule } from "@angular/core";
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    NgbDropdownModule,
    NgbTooltipModule
  ],
  exports: [
    NgbDropdownModule,
    NgbTooltipModule
  ]
})
export class NgBootstrapModule { }