import { Component, OnInit, Input, TemplateRef, ElementRef, ContentChild } from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss']
})
export class ShowMoreComponent {
  @Input()
  collection: Array<any> = [];

  @Input()
  visibleItems: number = 4;

  @Input()
  marginLeft: number = 0;

  @Input()
  once: boolean = false;

  showMore: boolean = false;

  currentScroll: number;

  @ContentChild('itemTemplate', { static: true })
  itemTemplate: TemplateRef<ElementRef>;

  show() {
    this.currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    this.showMore = true;
  }

  hide() {
    this.showMore = false;
    window.scrollTo(0, this.currentScroll);
  }
}
