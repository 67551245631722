import { Component, Input } from '@angular/core';
import { SearchResultItem } from 'src/search/models/search-result-item';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/infrastructure/locale.service';
import { faTag } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss', '../../../common.scss']
})
export class SearchResultComponent {
  @Input('model') model: SearchResultItem;

  faCopy = faCopy;
  bookmarkIcon = faTag;

  constructor(
    private translate: TranslateService,
    public locale: LocaleService
  ) { }
}
