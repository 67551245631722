import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { SearchField } from 'src/search/models/search-field';
import { SearchFieldType } from 'src/search/models/search-field-type.enum';
import { Classification } from 'src/classifications/models/classification';
import { SearchState } from 'src/search/services/search.state';

@Component({
  selector: 'app-organization-view',
  templateUrl: './organization-view.component.html',
  styleUrls: [
    '../../styles/common.scss',
    './organization-view.component.scss'
  ]
})
export class OrganizationViewComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private state: SearchState
  ) {
    this.route.data.subscribe(({ data }) => this.organization = data);
  }

  organization: Classification;
  faSearch = faSearch;

  clickClassification(classification: Classification) {
    // If we are not in a leaf node
    if (classification.children && classification.children.length > 0) {
      classification.isOpen = !classification.isOpen;
    }
    // If we are in a leaf node
    else {
      this.selectClassification(classification);
    }
  }

  selectClassification(classification: Classification) {
    this.state.change$.next({
      q: [new SearchField(SearchFieldType.classifications, classification.id, classification)]
    });
    this.router.navigate(['/search']);
  }
}
