import { Component, OnInit, Input } from '@angular/core';
import { copyText } from 'src/infrastructure/utils';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'publication-identifier',
  templateUrl: './publication-identifier.component.html',
  styleUrls: ['./publication-identifier.component.scss']
})
export class PublicationIdentifierComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  @Input()
  identifier: {
    type: string;
    value: string;
  };
  faCopy = faCopy;

  copyUrl(url: string, tooltip: NgbTooltip) {
    return copyText(url).subscribe();
  }
}
