import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getPaginatedNews, getPaginatedEvents } from './information-data-mock';
import { urlRegex } from 'src/infrastructure/utils';
import { InformationItem } from './information-type';
import { InformationType } from './information-type.enum';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { LocaleService } from 'src/infrastructure/locale.service';
import { InformationResource } from './information.resource';


@Component({
  selector: 'information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  news: InformationItem[] = [];
  events: InformationItem[] = [];
  courses: InformationItem[] = [];
  documents: InformationItem[] = [];
  sections = [];
  InformationType = InformationType;
  faAngleDown = faAngleDown;


  constructor(
    private sanitizer: DomSanitizer,
    public locale: LocaleService,
    private resource: InformationResource
  ) {
  }

  ngOnInit() {
    this.resource.getNews(0, 5)
      .subscribe(({ items }) => {
        this.sections[0] = {
          type: InformationType.news,
          data: items.map(item => this.processResources(item))
        }
      });

    this.resource.getEvents(0, 6)
      .subscribe(({ items }) => {
        this.sections[1] = {
          type: InformationType.events,
          data: items.map(item => this.processResources(item))
        }
      });
  }

  processResources(item: InformationItem) {
    item.resourcesSafe = item.resources
      .map(resource => {
        if (resource.url.trim().match(urlRegex))
          return {
            name: resource.name,
            url: this.sanitizer.bypassSecurityTrustUrl(resource.url.trim())
          };
        else
          return null;
      })
      .filter(resource => resource);

    return item;
  }
}
