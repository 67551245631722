import { Component, OnInit, Input } from '@angular/core';
import { SearchFieldType, SearchFieldCategories } from 'src/search/models/search-field-type.enum';
import { SearchFieldControlType, SEARCH_FIELD_SPEC } from 'src/search/models/search-field-type-spec';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { SearchField } from 'src/search/models/search-field';
import { Router } from '@angular/router';
import { SearchMode } from 'src/search/models/search-mode.enum';
import { SearchState } from 'src/search/services/search.state';

@Component({
  selector: 'search-sidebar',
  templateUrl: './search-sidebar.component.html',
  styleUrls: ['./search-sidebar.component.scss']
})
export class SearchSidebarComponent implements OnInit {

  constructor(
    private router: Router,
    private state: SearchState
  ) { }

  ngOnInit() {
  }

  @Input()
  searchFields: SearchField[] = [];

  @Input()
  categories: any = {};

  SearchFieldType = SearchFieldType;
  SearchFieldControlType = SearchFieldControlType;
  SEARCH_FIELD_SPEC = SEARCH_FIELD_SPEC;
  SearchFieldCategories = SearchFieldCategories;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;

  search() {
    this.state.change$.next({
      q: this.searchFields,
      page: 0,
      mode: SearchMode.simple
    });
  }

  handleCheckboxChange(fieldType: SearchFieldType, item: any) {
    if (item.selected)
      this.removeFilter(fieldType, item);
    else
      this.searchFields.push(new SearchField(fieldType, item.key, item));

    this.search();
  }

  handleFilterRemove(index: number) {
    if (this.removeAt(index))
      this.search();
  }

  private removeFilter(fieldType: SearchFieldType, item: any) {
    let index = this.searchFields.findIndex((e: SearchField) => e.type == fieldType
      && e.searchTerm == item.key);

    this.removeAt(index);
  }

  // @Debug()
  private removeAt(index: number): boolean {
    if (index > -1) {
      this.searchFields.splice(index, 1);
      return true;
    }
    else
      return false;
  }

  clearFilter() {
    this.state.change$.next({});
  }
}
