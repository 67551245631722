import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'en',
    loadChildren: () => import('./english-pages.module').then(e => e.EnglishPagesModule)
  },
  {
    path: 'bg',
    loadChildren: () => import('./bulgarian-pages.module').then(e => e.BulgarianPagesModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PagesModule { }
