import { Component, Input } from "@angular/core";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'loading-section',
    templateUrl: 'loading-section.component.html'
})
export class LoadingSectionComponent {

    loadingIcon = faSpinner;

    @Input() loadingText = 'loadingSection.text';
    @Input() iconSize = 'fa-3x';
    @Input() marginSize = '4';
}