import { Component, OnInit, Input } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { urlRegex } from 'src/infrastructure/utils';

@Component({
  selector: 'link-or-text',
  templateUrl: './link-or-text.component.html',
  styleUrls: ['./link-or-text.component.scss']
})
export class LinkOrTextComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  textValue: string;
  safeUrl: SafeUrl;

  @Input('uri')
  set uri(value: string) {
    if (value && value.trim() && value.trim().match(urlRegex)) {
      this.textValue = value;
      this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(value.trim());
    } else {
      this.textValue = value;
      this.safeUrl = null;
    }
  }
}
