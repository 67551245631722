import { NgModule } from "@angular/core";

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { PublicationViewComponent } from './components/publication-view/publication-view.component';
import { PublicationRouting } from './publication.routing';
import { PublicationViewResolverService } from './services/publication-view-resolver.service';
import { ComponentsModule } from 'src/components/components.module';
import { SharedModule } from 'src/shared/shared.module';
import { PublicationDetailsComponent } from './components/publication-details/publication-details.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FontAwesomeModule,
    ComponentsModule,
    SharedModule,
    PublicationRouting
  ],
  declarations: [
    PublicationViewComponent,
    PublicationDetailsComponent
  ],
  providers: [
    PublicationViewResolverService
  ]
})
export class PublicationModule { }