import { Component, HostListener, ViewChild, ElementRef } from "@angular/core";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Configuration } from 'src/infrastructure/configuration/configuration';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { EAuthSendRequestModalComponent } from "../e-auth/components/e-auth-send-request-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EAuthUserService } from "../e-auth/services/e-auth-user.service";
import { EAuthProfileModalComponent } from "../e-auth/components/e-auth-profile-modal.component";

@Component({
  selector: 'ab-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss']
})
export class MenuComponent {

  constructor(
    config: Configuration,
    sanitizer: DomSanitizer,
    translate: TranslateService,
    private modalService: NgbModal,
    public eAuthUserService: EAuthUserService
  ) {
    this.loginUrl = sanitizer.bypassSecurityTrustUrl(`${config.internalAppUrl}/login`);
    translate.onLangChange.subscribe((e: LangChangeEvent) => this.lang = e.lang);
  }

  lang: string;
  loginUrl: SafeUrl;
  faBars = faBars;

  @ViewChild("collapseContainer", { static: false }) collapseContainer: ElementRef;
  @ViewChild('toggleBtn', { static: true }) toggleBtn: ElementRef;

  @HostListener('document:click', ['$event']) onClickOutside(event: MouseEvent): void {
    if (!this.collapseContainer.nativeElement.contains(event.target)
      && this.collapseContainer.nativeElement.classList.contains('show'))
      this.toggleBtn.nativeElement.click();
  }

  hideCollapseContainer(container: HTMLElement) {
    setTimeout(() => this.collapseContainer.nativeElement.classList.remove('show'), 100)
  }

  loginEAuth() {
    this.modalService.open(EAuthSendRequestModalComponent, { size: 'lg', keyboard: false, backdrop: 'static' });
  }

  profileEAuth() {
    this.modalService.open(EAuthProfileModalComponent, { keyboard: false, backdrop: 'static' });
  }
}