import { Component, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingIndicatorService } from 'src/infrastructure/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { getPageTitle, getPageLanguage, getNavigationForGtag, gtagConfigEvent, initLocalization } from 'src/infrastructure/utils';
import { LocaleService } from 'src/infrastructure/locale.service';
import { Configuration } from 'src/infrastructure/configuration/configuration';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: []
})
export class AppComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private loadingIndicator: LoadingIndicatorService,
    private titleService: Title,
    private localeService: LocaleService,
    private config: Configuration
  ) {
    initLocalization(this.activatedRoute, this.translate, this.localeService);
  }

  isLoading: boolean = false;

  ngAfterViewInit() {
    this.loadingIndicator.change$.subscribe((isLoading: boolean) => {
      setTimeout(() => this.isLoading = isLoading, 100);
    });

    getPageTitle(this.router, this.activatedRoute, this.translate)
      .subscribe(title => this.titleService.setTitle(title));

    getPageLanguage(this.router)
      .subscribe(lang => this.translate.use(lang));

    if (this.config.enableAnalytics) {
      gtag('config', this.config.analyticsTrackingId);
      getNavigationForGtag(this.router)
        .subscribe(url => gtagConfigEvent(url, this.config.analyticsTrackingId));
    }
  }
}
