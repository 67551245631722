import { Component, OnInit } from "@angular/core";
import { Configuration } from "src/infrastructure/configuration/configuration";

@Component({
  selector: "app-reward-redirect",
  template: ""
})
export class RedirectRewardComponent implements OnInit {
  constructor(
    private configuration: Configuration
  ) { }

  ngOnInit(): void {
    window.location.href = `${this.configuration.internalAppUrl}/reward`;
  }
}
