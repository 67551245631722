import { Component, Input, ContentChild, TemplateRef, ElementRef, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'foreign-name-select',
  templateUrl: 'foreign-name-selector.component.html'
})
export class ForeignNameSelectorComponent<T extends { name: string, nameEn: string }> implements OnInit, OnDestroy {


  constructor(
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  @Input('model')
  model: T;

  @ContentChild('valueTemplate', { static: true })
  valueTemplate: TemplateRef<ElementRef> = null;
  value = null;

  s: Subscription = null;

  ngOnInit() {
    this.s = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectValue(event.lang);
      this.changeDetector.detectChanges();
    });

    this.selectValue(this.translate.currentLang);
  }

  selectValue(lang: string) {
    this.value = lang === "en" ? this.model.nameEn || this.model.name || "" : this.model.name || "";
  }

  ngOnDestroy() {
    if (this.s)
      this.s.unsubscribe();
  }
}