import { Component, OnInit, Input } from '@angular/core';
import { Publication } from 'src/publication/models/publication';
import { LocaleService } from 'src/infrastructure/locale.service';
import { SourceType } from 'src/publication/models/source-type.enum';

@Component({
  selector: 'publication-subheading-bar',
  templateUrl: './publication-subheading-bar.component.html',
  styleUrls: ['./publication-subheading-bar.component.scss']
})
export class PublicationSubheadingBarComponent implements OnInit {

  constructor(
    public locale: LocaleService
  ) { }

  ngOnInit() {
  }

  @Input()
  model: Publication;

  SourceType = SourceType;
}
