//Chosse Locale From This Link
//https://github.com/angular/angular/tree/master/packages/common/locales

import { Injectable } from '@angular/core';
import { Debug, DebugVerbosity } from './debug.decorator';

@Injectable()
export class LocaleService {
  constructor() { }

  private _defaultLocale = 'bg-BG';
  private _locale: string;
  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || 'en-GB';
  }

  private _defaultCulture = 'bg';
  private _culture: string;
  set culture(value: string) {
    this._culture = value;
    localStorage.setItem("culture", value);
  }

  get culture() {
    return localStorage.getItem("culture") || this._culture || this._defaultCulture;
  }

  // @Debug({
  //   verbosity: DebugVerbosity.methodVerbose
  // })
  public changeCulture(culture: string) {
    switch (culture) {
      case 'en': {
        this._locale = 'en-GB';
        this.culture = culture;
        break;
      }
      case 'bg': {
        this._locale = 'bg-BG';
        this.culture = culture;
        break;
      }
      default: {
        this._locale = this._defaultLocale;
        this.culture = this._defaultCulture;
        break;
      }
    }
  }
}