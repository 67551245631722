import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Publication } from '../../models/publication';
import { faFilePdf, faAngleLeft, faArrowLeft, faChevronLeft, faBackward, faTags, faTag, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { scrollToTop } from 'src/infrastructure/utils';
import { SourceType } from 'src/publication/models/source-type.enum';
import { LocaleService } from 'src/infrastructure/locale.service';


@Component({
  templateUrl: 'publication-view.component.html',
  styleUrls: ['publication-view.component.scss', '../../../common.scss']
})
export class PublicationViewComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public locale: LocaleService
  ) {

    this.route.data.subscribe((data: { publication: Publication }) => this.publication = data.publication);
  }

  publication: Publication;
  faFilePdf = faFilePdf;
  bookmarkIcon = faTag;
  informationIcon = faInfoCircle;
  backToSearchIcon = faArrowLeft;//faChevronLeft;//faBackward;//faAngleLeft;
  showDetails = false;
  SourceType = SourceType;

  ngOnInit(): void {
    scrollToTop();
  }

  back() {
    if (window.history.state && window.history.state.navigationId && window.history.state.navigationId > 1)
      window.history.back();
    else
      this.router.navigate(['/search']);
  }
}