import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicationSubheadingBarComponent } from './components/publication-subheading-bar/publication-subheading-bar.component';
import { ComponentsModule } from 'src/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { PublicationIdentifierComponent } from './components/publication-identifier/publication-identifier.component';
import { NgBootstrapModule } from 'src/infrastructure/ng-bootstrap.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgBootstrapModule,
    FontAwesomeModule,
    ComponentsModule,
  ],
  declarations: [
    PublicationSubheadingBarComponent,
    PublicationIdentifierComponent
  ],
  exports: [
    PublicationSubheadingBarComponent,
    PublicationIdentifierComponent
  ]
})
export class SharedModule { }
