import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchComponent } from './components/search/search.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NewsComponent } from './components/news/news.component';
import { EventsComponent } from './components/events/events.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
    data: {
      title: 'pages.home'
    }
  },
  {
    path: 'search',
    component: SearchComponent,
    data: {
      title: 'pages.search'
    }
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'events',
    component: EventsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class SearchRoutingModule { }
