import { Component, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { InformationItem } from '../information-type';
import { LocaleService } from 'src/infrastructure/locale.service';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { InformationType } from '../information-type.enum';

@Component({
  selector: 'app-information-item',
  templateUrl: 'information-item.component.html',
  styleUrls: ['./information-item.component.css']
})

export class InformationItemComponent implements AfterViewInit {
  @Input() item: InformationItem;
  @Input() section: any;

  @ViewChild('content', { static: true }) content: ElementRef;
  @ViewChild('para', { static: true }) para: ElementRef;

  InformationType = InformationType;
  faLinkIcon = faCaretRight;
  faFileIcon = faFile;

  maxHeight: string = '12.5em';

  constructor(
    public locale: LocaleService,
    public cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.item.showMore = this.para.nativeElement.clientHeight > this.content.nativeElement.clientHeight;
    this.cd.detectChanges();
  }
}