import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResource } from 'src/infrastructure/base.resource';
import { Configuration } from 'src/infrastructure/configuration/configuration';
import { SearchResult } from '../models/search-result';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { urlRegex } from 'src/infrastructure/utils';

@Injectable()
export class SearchResource extends BaseResource {
  constructor(
    protected http: HttpClient,
    protected configuration: Configuration,
    private sanitizer: DomSanitizer
  ) {
    super(http, configuration, '');
  }

  search(search: any, aggregate?: any): Observable<SearchResult> {
    return this.http.post<SearchResult>(`${this.baseUrl}/search`, { search, aggregate })
  }

  get categories() {
    return this.http.get<any>(`${this.baseUrl}/categories`);
  }

  sanitizeUrl(url: string) {
    if (url != null && url.trim() && url.trim().match(urlRegex))
      return this.sanitizer.bypassSecurityTrustUrl(url.trim());
    else return null;
  }
}