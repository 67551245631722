import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretRight, faDatabase, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Classification } from 'src/classifications/models/classification';
import { SearchField } from 'src/search/models/search-field';
import { SearchFieldType } from 'src/search/models/search-field-type.enum';
import { SearchState } from 'src/search/services/search.state';

@Component({
  templateUrl: './organization.component.html',
  styleUrls: ['../../../page-styles.scss', './organization.component.scss']
})
export class OrganizationComponent {
  constructor(
    route: ActivatedRoute,
    private router: Router,
    private state: SearchState
  ) {
    route.data.subscribe(({ data }) => {
      this.data = data;
    });
  }

  SearchFieldType = SearchFieldType;
  data: Classification[] = [];

  collectionIcon = faDatabase;
  closedIcon = faCaretRight;
  openIcon = faCaretDown;
  faSearch = faSearch;

  back() {
    window.history.back();
  }

  clickClassification(classification: Classification) {
    // If we are not in a leaf node
    if (classification.children && classification.children.length > 0) {
      classification.isOpen = !classification.isOpen;
    }
    // If we are in a leaf node
    else {
      this.selectSearchFiled(SearchFieldType.classifications, classification.id, classification);
    }
  }

  clickOrganization(institution: Classification) {
    if (institution.children && institution.children.length) {
      institution.isOpen = !institution.isOpen;
    } else {
      this.selectSearchFiled(SearchFieldType.institution, institution.id, institution);
    }
  }

  private selectSearchFiled(type: SearchFieldType, value: number, model: Classification) {
    this.state.change$.next({
      q: [new SearchField(type, value, model)]
    });
    this.router.navigate(['/search']);
  }
}
