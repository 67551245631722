import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { SearchMode } from '../models/search-mode.enum';
import { SearchEvent } from '../models/search-event';

const storage = {
  load: () => {
    return JSON.parse(sessionStorage.getItem('lastSearch')) || {
      q: [],
      page: 0,
      mode: SearchMode.simple
    };
  },
  save: (state: SearchEvent) => sessionStorage.setItem('lastSearch', JSON.stringify(state))
};

@Injectable({
  providedIn: 'root'
})
export class SearchState {
  change$: BehaviorSubject<SearchEvent> = new BehaviorSubject(storage.load());

  constructor() {
    this.change$.subscribe(search => storage.save(search));
  }
}